import decMontantsFields from "./decMontantsFields"

export default [
    ...decMontantsFields,
    "date",
    "avancementChantier",
    "statut",
    "affaire",
    "facturationEnCours",
    "dateComptabilise",
    "pointMensuel",
    "ligneEngageCU",
    "ligneEngageQte"
]