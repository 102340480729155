import { render, staticRenderFns } from "./PointGestion.vue?vue&type=template&id=11275c73&scoped=true&"
import script from "./PointGestion.vue?vue&type=script&lang=js&"
export * from "./PointGestion.vue?vue&type=script&lang=js&"
import style0 from "./PointGestion.vue?vue&type=style&index=0&id=11275c73&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11275c73",
  null
  
)

export default component.exports