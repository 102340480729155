<template>
    <div>
        <loading-card v-if="!fullyLoaded">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else-if="deleting">Suppression en cours ...</template>
            <template v-else>Chargement du point de gestion ...</template>
        </loading-card>
        <template v-else>
                <b-form @keydown.enter.prevent>
                    <validation-observer ref="simpleRules">
                    <!-- INFORMATIONS -->
                        <b-card title="Informations" class="card-info">
                            <b-row align-v="center">
                                <b-col lg="6" sm="12">
                                    Affaire : <b>N° {{affaire.num}}</b> - {{affaire.titre}}
                                </b-col>
                                <b-col lg="3" sm="6" align-v="center">
                                    Type : {{affaire.type != null ? affaire.type.libelle : '-'}}
                                </b-col>
                                <b-col lg="3" sm="6">
                                    Chargé Affaire : {{affaire.userChargeAffaire.firstname}} {{affaire.userChargeAffaire.lastname}} - {{affaire.userChargeAffaire.username}}
                                </b-col>
                                <b-col lg="2" sm="4">
                                    <b-form-group label="Date" label-for="date">
                                        <validation-provider #default="{ errors }" name="Date" rules="required">
                                            <b-form-datepicker
                                                locale="fr" 
                                                v-model="date"
                                                :disabled="!isBrouillon"
                                                id="date"
                                                placeholder="Date"
                                                :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" sm="4">
                                    <b-form-group label="Avancement réel (%)" label-for="avancementChantier">
                                        <validation-provider #default="{ errors }" name="Avancement Réel" :rules="validation ? 'required' : ''">
                                            <b-input-group append="%">
                                            <b-input type="number"
                                                id="avancementChantier"
                                                v-model="avancementChantier"
                                                class=" text-right"
                                                :class="errors.length > 0 ? 'is-invalid' : ''"
                                                
                                                placeholder="0,00"
                                                :disabled="!isBrouillon"
                                            />
                                            </b-input-group>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="2" sm="4">
                                    <b-form-group label="Avancement Théorique" label-for="avancementTheorique">
                                        <b-input-group append="%">
                                        <b-input
                                            id="avancementTheorique"
                                            :value="formatNumber(avancementTheorique,'')"
                                            class=" text-right"                                                
                                            :disabled="true"
                                        />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="2" sm="4">
                                    <b-form-group label="Point Gestion Mensuel" label-for="pointMensuel">
                                        <b-form-checkbox
                                            id="pointMensuel"
                                            v-model="pointMensuel"
                                            class="mr-0 mt-50"
                                            name="is-rtl"
                                            switch
                                            :disabled="!isBrouillon"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col lg="2" sm="4">
                                    <b-form-group label="Date Comptabilisée" label-for="dateComptabilise">
                                        <validation-provider #default="{ errors }" name="Date Comptabilisée" rules="required">
                                            <b-form-datepicker
                                                locale="fr" 
                                                v-model="dateComptabilise"
                                                :disabled="!isBrouillon || pointMensuel"
                                                :title="pointMensuel ? 'Le choix de la date comptabilisée n\'est libre que pour pour les points non mensuels' : ''"
                                                id="dateComptabilise"
                                                placeholder="Date Comptabilisée"
                                                :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                        
                                    </b-form-group>
                                </b-col>
                                <b-col lg="2" sm="4">
                                    <span class="text-primary">Les éléments financiers pris en compte sont ceux antèrieurs à cette date</span>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="dateComptabilise == null">
                            <b-card-text>Veuillez sélectionner une date pour le point de gestion pour pouvoir initialiser les valeurs.</b-card-text>
                        </b-card>

                        <template v-else>
                            <b-overlay :show="loadingComptas || loadingDecs || loadingPointGestion">
                            <b-row deck v-if="dateComptabilise != null" ref="el">
                                <b-col lg="4" :style="{paddingRight: '0px !important', paddingLeft: '0px'}">
                                    <DecMontants 
                                        :values="montantsDecs"
                                        :title="'Décomposition du DEC'"
                                        @update="updateMontant"
                                        :showInputs="false"
                                        :showTotalRow="true"
                                        @mounted="updateRowsHeights"
                                        :forcedHeights="rowsHeights"
                                        :fontSize="'12px'"
                                        :emitTotal="true"
                                        :bus="bus"
                                        :showEngage="true"
                                        @total="decTotal = $event"
                                        :formRules="validation ? 'required' : ''"
                                    >
                                        <!-- <template #extras-row-0>
                                            <td class="pl-2 border-right-primary">Engagés</td>
                                            <td class="pb-1 pr-2 text-center"/>
                                        </template> -->
                                    </DecMontants>
                                </b-col>
                                <b-col lg="3" :style="{paddingRight: '0px !important', paddingLeft: '0px'}">
                                    <dec-montants 
                                        :bus="bus"
                                        :values="montantsCompta"
                                        :title="'Comptabilité'"
                                        @update="updateMontant"
                                        :showInputs="false"
                                        :showLabelColumn="false"
                                        :showUnitColumn="false"
                                        :showTotalRow="true"
                                        @mounted="updateRowsHeights"
                                        :forcedHeights="rowsHeights"
                                        :fontSize="'12px'"
                                        :showEngage="true"
                                        :emitTotal="true"
                                        @total="comptaTotal = $event"
                                        :extraColumns="[{header: '%', target: 'avancement', style: {width: '75px !important'}, total: totalAvancement, prefix: '%'}]"
                                        :formRules="validation ? 'required' : ''"
                                        :countOneIfZero="true"
                                    >
                                        <!-- <template #extras-row-0>
                                            <td>{{parseFloat(0).toFixed(2)}}</td>
                                            <td>{{formatNumber(parseFloat(0).toFixed(2))}}</td>
                                            <td class="pb-1 pr-2 text-right">{{formatNumber(0)}}</td>
                                        </template> -->
                                    </dec-montants>
                                </b-col>
                                <b-col lg="3" :style="{paddingRight: '0px !important', paddingLeft: '0px'}">
                                    <dec-montants 
                                        :bus="bus"
                                        :values="dataMontantsEdit"
                                        :title="'Reste à faire'"
                                        @update="updateMontant"
                                        :disableFields="!isBrouillon"
                                        :showLabelColumn="false"
                                        :showUnitColumn="false"
                                        :showTotalRow="true"
                                        @mounted="updateRowsHeights"
                                        :forcedHeights="rowsHeights"
                                        :fontSize="'12px'"
                                        :formRules="validation ? 'required' : ''"
                                        :showEngage="true"
                                    />
                                </b-col>

                                <b-col lg="2" :style="{paddingRight: '0px !important', paddingLeft: '0px'}">
                                    <dec-montants 
                                        :bus="bus"
                                        :values="montantsTotal"
                                        :title="'Total'"
                                        @update="updateMontant"
                                        :showInputs="false"
                                        :showLabelColumn="false"
                                        :showUnitColumn="false"
                                        :showTotalRow="true"
                                        @mounted="updateRowsHeights"
                                        :forcedHeights="rowsHeights"
                                        :fontSize="'12px'"
                                        :emitTotal="true"
                                        @total="totalTotal = $event"
                                        :formRules="validation ? 'required' : ''"
                                        :countOneIfZero="true"
                                        :showEngage="true"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                        </template>
                    </validation-observer>
                </b-form>

            <template v-if="dateComptabilise != null">
                <b-overlay :show="reloadingPvaos || loadingComptas || loadingDecs">
                <b-form>
                    <b-card class="card-other">
                        <b-row><h5>Suivi des ventes certififées</h5></b-row>
                        <b-row>
                            <table class="w-100 table b-table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Libellé</th>
                                        <th>Montant</th>
                                        <th :style="{width: '15%'}" class="text-center">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(dec, index) in decs" :key="`dec-${index}`">
                                        <td><router-link :to="`/decs/${dec.id}/edit`">DEC N° {{dec.num}}</router-link></td>
                                        <td>{{formatNumber(dec.prixVenteHT)}}</td>
                                        <td/>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <b-row class="mt-2"><h5>Suivi des ventes annoncées</h5></b-row>

                        <b-row>
                            <table class="w-100 table b-table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Libellé</th>
                                        <th>Montant</th>
                                        <th :style="{width: '15%'}" class="text-center">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <validation-observer :ref="`pvao_${index}`" tag="tr" v-for="(pvao, index) in pvaos" :key="`pvao_${index}`">
                                            <td>
                                                <validation-provider #default="{ errors }" name="Libelle" :rules="'required'">
                                                    <b-form-input
                                                        v-model="pvao.libelle"
                                                        type="text"
                                                        placeholder="Libelle"
                                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        :disabled="isPvaoLoading(pvao)"
                                                    />
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </td>
                                            <td>
                                                <validation-provider #default="{ errors }" name="Montant" :rules="'required'">
                                                    <b-form-input
                                                        type="number"
                                                        v-model="pvao.prixVenteHT"
                                                        placeholder="0,00"
                                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        :disabled="isPvaoLoading(pvao)"
                                                    />
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </td>
                                            <td class="text-center">
                                                <template v-if="!isPvaoLoading(pvao)">
                                                    <b-button
                                                        variant="gradient-danger"
                                                        class="btn-icon"
                                                        @click="deletePvao(pvao.id)"
                                                    >
                                                        <feather-icon icon="Trash2Icon" />
                                                    </b-button>
                                                </template>
                                                <b-spinner
                                                    v-else
                                                    type="grow"
                                                    variant="primary"
                                                    class="mb-2 mt-2"
                                                />
                                            </td>
                                    </validation-observer>
                                </tbody>
                            </table>
                        </b-row>

                        <b-row class="mt-1">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                @click.prevent="addPvao"
                            >
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>Ajouter</span>
                            </b-button>
                        </b-row>

                        <b-row class="mt-2 pt-2 text-primary border-top-black" :style="{fontSize: '14px !important'}">
                            <b-col lg="9" align-v="center">
                                <b>Total des ventes annoncées</b>
                            </b-col>

                            <b-col lg="3" class="text-right" align-v="center">
                                <b>{{formatNumber(totalVenteAnnonce)}}</b>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-form>
                </b-overlay>

                <b-row >
                    <b-col offset-lg="3" lg="3">
                        <b-card class="card-other">
                            <b-row>
                                <b-col lg="8">
                                    Marge DEC
                                </b-col>
                                <b-col lg="4" class="text-right">
                                    <b>{{formatNumber(margeDec, '%')}}</b>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8">
                                    Marge Actualisée
                                </b-col>
                                <b-col lg="4" class="text-right text-primary">
                                    <b>{{formatNumber(margeActualisee * 100, '%')}}</b>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col lg="2">
                        <b-card class="card-other">
                            <b-row>
                                <b-col lg="12">Perte ou Gain Brut</b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" :class="perteGainBrut > 0 ? 'text-success' : 'text-danger'"><b>{{formatNumber(perteGainBrut)}}</b></b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>

                <b-overlay :show="loadingComptas || loadingDecs">
                <b-card title="Facturation" class="card-other">
                    <b-row>
                        <table class="w-100 table b-table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Activité {{labelM1}}</th>
                                    <th>Total Facturation {{labelM1}}</th>
                                    <th>Reste à facturer</th>
                                    <th>Surfacturation</th>
                                    <th>Facturation En cours </th>
                                    <th>Surfacturation actualisée</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{formatNumber(activiteM1)}}</td>
                                    <td>{{formatNumber(totalFacturationM1)}}</td>
                                    <td>{{formatNumber(resteAFacturer)}}</td>
                                    <td :class="surfacturation > 0 ? 'text-success' : 'text-danger'">{{formatNumber(surfacturation)}}</td>
                                    <td>
                                        <b-form-group>
                                            <validation-provider #default="{ errors }" :rules="validation ? 'required' : ''">
                                                <b-input type="number"
                                                    id="facturationEnCours"
                                                    v-model="facturationEnCours"
                                                    class=" text-right"
                                                    :class="errors.length > 0 ? 'is-invalid' : ''"
                                                    placeholder="0,00"
                                                    :disabled="!isBrouillon"
                                                />
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </td>
                                    <td :class="surfacturationActualisee > 0 ? 'text-success' : 'text-danger'">{{formatNumber(surfacturationActualisee)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                    <b-row class="mt-2 pt-2 text-primary border-top-black" :style="{fontSize: '14px !important'}">
                        <b-col lg="9" align-v="center">
                            <b>Total facturation</b>
                        </b-col>

                        <b-col lg="3" class="text-right" align-v="center">
                            <b>{{formatNumber(totalFacturation)}}</b>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <table class="w-100 table b-table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Libellé</th>
                                    <th>Activité</th>
                                    <th>Facturation</th>
                                    <th>Reste à facturer</th>
                                    <th>Surfacturation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>N-1</td>
                                    <td>{{formatNumber(activiteN1)}}</td>
                                    <td>{{formatNumber(totalFacturationN1)}}</td>
                                    <td>{{formatNumber(resteAFacturerN1)}}</td>
                                    <td :class="surfacturationN1 > 0 ? 'text-success' : 'text-danger'">{{formatNumber(surfacturationN1)}}</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>{{formatNumber(activiteN)}}</td>
                                    <td>{{formatNumber(totalFacturationN)}}</td>
                                    <td>{{formatNumber(resteAFacturerN)}}</td>
                                    <td :class="surfacturationN > 0 ? 'text-success' : 'text-danger'">{{formatNumber(surfacturationN)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                </b-card>

                <b-card title="Facturation" class="card-other">
                    <b-row>
                        <table class="w-100 table b-table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>&#x2039; 15 jours</th>
                                    <th>&#x2039; 30 jours</th>
                                    <th>&#x2039; 60 jours</th>
                                    <th>&#x2039; 90 jours</th>
                                    <th>Au delà</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{formatNumber(getEncaissementRetard(0, 15))}}</td>
                                    <td>{{formatNumber(getEncaissementRetard(15, 30))}}</td>
                                    <td>{{formatNumber(getEncaissementRetard(30, 60))}}</td>
                                    <td>{{formatNumber(getEncaissementRetard(60, 90))}}</td>
                                    <td>{{formatNumber(getEncaissementRetard(90, null))}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                </b-card>
                </b-overlay>
            </template>

                <b-row>
                    <b-col sm="12" lg="8" md="8">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="goBack" class="mr-1">
                            <feather-icon icon="ListIcon" class="mr-50"/>
                            <span class="align-middle">
                                Retour
                            </span>
                        </b-button>

                        <b-button variant="gradient-success" type="submit" @click.prevent="validationForm('')" class="mr-1" v-if="isBrouillon || reopen">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">{{creating ? "Créer" : "Enregistrer"}}</span>
                            <b-spinner small class="ml-50" v-show="saving"></b-spinner>
                        </b-button>
                        <b-button variant="gradient-success" type="submit" @click.prevent="validationForm(showValiderButton)" class="mr-1" v-if="!reopen && showValiderButton != ''">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">Valider</span>
                            <b-spinner small class="ml-50" v-show="saving"></b-spinner>
                        </b-button>

                        <b-button variant="gradient-primary" target="_blank" v-if="pointGestionReouvrable && !reopen" class="mr-1" @click.prevent="reopenPointGestion">
                            <span class="align-middle">Réouvrir</span>
                        </b-button>

                        <b-button variant="gradient-primary" type="submit" @click.prevent="exportPointGestion" v-if="pointGestion != null && !creating">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">Exporter</span>
                        </b-button>
                    </b-col>
                </b-row>
            
        </template>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import decMontantsMixin from "@/views/setups/decMontantsMixin"
    import DecMontants from "./DecMontants.vue"
    import LoadingCard from "@/views/GDLoadingCard"
    import expectedFields from "./pointGestionFields"
    const retardEncaissementFields = ["retardEncaissement15", "retardEncaissement30", "retardEncaissement60", "retardEncaissement90", "retardEncaissementAudela"];
    import decMontantsFields from "./decMontantsFields"
    import { computed, ref } from '@vue/composition-api'
    import {computedFactory, storeComputedFactory} from "@/services/computedFactory"
    import {formatNumber, generateUniqid, asyncForEach} from "@/services/utils"
    import pointGestionApi from "@/api/pointGestionApi"
    import miscApi from "@/api/miscApi"
    import affaireApi from "@/api/affaireApi";
    import comptabiliteApi from "@/api/comptabiliteApi";
    import decApi from "@/api/decApi";
    import pvaoApi from "@/api/pvaoApi"
    import moment from "moment";
    import _ from "lodash"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { required } from '@validations'
    import {downloadFile} from "@/api/api"
    import {mapGetters, mapState} from "vuex"

    import { useElementSize } from '@vueuse/core'
    import Vue from "vue"


    export default {
        mixins: [decMontantsMixin],
        components: {
            DecMontants,
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            ToastificationContent,
        },

        data() {
            return {
                prevRoute: null,

                saving: false,
                deleting: false,
                decs: [],
                pvaos: [],
                comptas: [],
                rowsHeights: null,
                loadingDecs: false,
                loadingComptas: false,
                loadingPointGestion: false,
                reopen: false,
                validation: false,
                loadingPvaos: [],
                reloadingPvaos: false,
                decTotal: 0,
                totalTotal: 0,
                comptaTotal:0,

                bus: new Vue(),
            }
        },

        watch:{
            date() {
                if(this.dateComptabilise == null)
                    this.dateComptabilise = this.date;
                if(this.pointMensuel)
                    this.initM1();
            },

            height(val) {
                this.rowsHeights = null;
                console.log("resize !", val)
                this.bus.$emit("resize")
            },

            dateComptabilise() {
                let affaireId = this.affaireId != null ? this.affaireId : this.affaire.id;
                this.fetchDecs(affaireId);
                this.fetchComptabilite(affaireId)
            },

            pointMensuel(newVal) {
                if(newVal && this.date != null) {
                    this.initM1();
                }
            }
        },

        setup(props, {root}) {
            // TODO modifier le computed factory pour pouvoir spécifier des types pour les attributs :
            // resource, pour savoir qu'on doit réduire l'objet à juste son IRI (apiPlatform), float pour savoir
            // que l'on doit le convertir en float (sinon erreur enregistrement api platform)
            const currentBreakPoint = computed(() => root.$store.getters['app/currentBreakPoint'])
            // TODO voir si on ne peut pas récupérer tous les fields attendus pour un groupe de serialization via APi platform
            let pointGestionBase = null;
            let creatingBase = !root.$route.params.hasOwnProperty("pointGestionId");
            if(creatingBase) {
                pointGestionBase = expectedFields.reduce((accumulator, field) => ({...accumulator, [field]: null}), {});
            }

            const pointGestion = ref(pointGestionBase);
            const creating = ref(creatingBase)

            const storeFields = ["decStatuts"]
            const affaireId = ref(root.$route.params.hasOwnProperty("affaireId") ? ref(root.$route.params.affaireId) : null)

            const el = ref(null)
            const { width, height } = useElementSize(el)

            return {
                el, width, height,
                affaireId,
                pointGestion,
                creating,
                expectedFields,
                ...computedFactory([...expectedFields, ...retardEncaissementFields], "pointGestion"),
                ...storeComputedFactory(storeFields, root.$store),
                currentBreakPoint,
                formatNumber,
                required,
            }
        },

        async beforeMount() {
            if(this.$route.params.hasOwnProperty("pointGestionId"))
                this.fetchPointGestion();
            else {
                this.pvaos = [];
                //initialisation en cas de création
                this.pointMensuel = true;
                this.date = moment().format("YYYY-MM-DD");
                this.dateComptabilise = moment(this.date, "YYYY-MM-DD").subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
            }

            if(this.affaireId != null && this.creating) {
                this.fetchAffaire();
                await this.fetchLastPointGestion(this.affaireId)
            }

            this.refreshStatuts();
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from.name
            })
        },

        computed: {
            labelM1() {
                return this.dateComptabilise == null ? "M-1" : "au " + moment(this.dateComptabilise, "YYYY-MM-DD").format("DD/MM/YYYY")
            },
            ...mapState({
                user: state => state.data.user
            }),
            ...mapGetters("data", ["isGranted", "showDecsOnlyUser"]),
            activiteN() {
                return this.comptasN.filter(c => c.type.mappedMontant != 'ligneEngage').reduce((accumulator, compta) => accumulator + -1 * parseFloat(compta.montantHT), 0) / (1 - this.margeActualisee)
            },

            avancementTheorique() {
                return this.decTotal == 0 ? 0 : (this.comptaTotal * 100) / this.decTotal;
            },

            activiteN1() {
                return this.comptasN1.filter(c => c.type.mappedMontant != 'ligneEngage').reduce((accumulator, compta) => accumulator + -1 * parseFloat(compta.montantHT), 0) / (1 - this.margeActualisee)
            },

            totalAvancement() {
                return this.decTotal == 0 ? 0 : ((this.comptaTotal * 100) / this.decTotal)
            },

            pointGestionReouvrable() {
                return this.statut.code == "CHARGE_AFFAIRE" && ((this.showDecsOnlyUser && this.user.id == this.affaire.userChargeAffaire.id) || this.isGranted("ROLE_DIRECTION"))
            },

            comptasNVente() {
                return this.comptas.filter(c => {
                    let fin = moment(this.dateM).endOf("year").endOf("day");
                    let debut = moment(this.dateM).startOf("year").startOf("day")
                    let comptaDate = moment(c.dateEcriture, "DD/MM/YYYY")

                    return comptaDate.isSameOrAfter(debut) && comptaDate.isSameOrBefore(fin) && c.type.code == "VT";
                })
            },

            comptasN1Vente() {
                return this.comptas.filter(c => {
                    let fin = moment(this.dateM).subtract(1, "years").endOf("year").endOf("day");
                    let debut = moment(this.dateM).subtract(1, "years").startOf("year").startOf("day")
                    let comptaDate = moment(c.dateEcriture, "DD/MM/YYYY")

                    return comptaDate.isSameOrAfter(debut) && comptaDate.isSameOrBefore(fin) && c.type.code == "VT";
                })
            },

            comptasN() {
                return this.comptas.filter(c => {
                    let fin = moment(this.dateM).endOf("year").endOf("day");
                    let debut = moment(this.dateM).startOf("year").startOf("day")
                    let comptaDate = moment(c.dateEcriture, "DD/MM/YYYY")

                    return comptaDate.isSameOrAfter(debut) && comptaDate.isSameOrBefore(fin) && c.type.code != "VT";
                })
            },

            comptasN1() {
                return this.comptas.filter(c => {
                    let fin = moment(this.dateM).subtract(1, "years").endOf("year").endOf("day");
                    let debut = moment(this.dateM).subtract(1, "years").startOf("year").startOf("day")
                    let comptaDate = moment(c.dateEcriture, "DD/MM/YYYY")

                    return comptaDate.isSameOrAfter(debut) && comptaDate.isSameOrBefore(fin) && c.type.code != "VT";
                })
            },

            comptasM1Vente() {
                return this.comptas.filter(c => {
                    let fin = moment(this.dateM1);
                    let comptaDate = moment(c.dateEcriture, "DD/MM/YYYY")

                    return comptaDate.isSameOrBefore(fin) && c.type.code == "VT";
                })
            },

            isBrouillon() {
                return this.statut.code == "BROUILLON"
            },

            showValiderButton() {
                if(this.isBrouillon && this.isGranted('ROLE_CHARGE_AFFAIRE'))
                    return "CHARGE_AFFAIRE"
                if(this.statut.code == "CHARGE_AFFAIRE" && this.isGranted("ROLE_DIRECTION"))
                    return "DIRECTION";

                return "";
            },

            fullyLoaded() {
                let fields = ["pointGestion", "saving", "deleting", "statut", "affaire"];
                return fields.every(f => this[f] != null)
            },

            totalVenteAnnonce() {
                let montants = [...this.decs.map(d => d.prixVenteHT), ...this.pvaos.map(pvao => pvao.prixVenteHT)];
                return montants.reduce((accumulator, montant) => accumulator + parseFloat(montant), 0)
            },

            margeDec() {
                return ((this.totalVenteAnnonce - this.decTotal) / this.totalVenteAnnonce) * 100
            },

            perteGainBrut() {
                return this.totalVenteAnnonce - this.totalTotal;
            },

            margeActualisee() {
                return (this.perteGainBrut / this.totalVenteAnnonce)
            },

            totalFacturation() {
                return this.totalFacturationM1 + parseFloat(this.facturationEnCours)
            },

            activiteM1() {
                let totEngage = this.montants("compta")[0].fields[2].total
                return (this.comptaTotal - totEngage) / (1 - this.margeActualisee)
            },

            totalFacturationM1() {
                return this.comptasM1Vente.reduce((accumulator, compta) => accumulator + parseFloat(compta.montantHT), 0)
            },

            totalFacturationN() {
                return this.comptasNVente.reduce((accumulator, compta) => accumulator + parseFloat(compta.montantHT), 0)
            },

            totalFacturationN1() {
                return this.comptasN1Vente.reduce((accumulator, compta) => accumulator + parseFloat(compta.montantHT), 0)
            },

            resteAFacturerN() {
                return this.totalVenteAnnonce - this.totalFacturationN - this.totalFacturationN1
            },

            resteAFacturerN1() {
                return this.totalVenteAnnonce - this.totalFacturationN1
            },

            surfacturationN()  {
                return this.totalFacturationN - this.activiteN;

            },

            surfacturationN1() {
                return this.totalFacturationN1 - this.activiteN1;
            },

            resteAFacturer() {
                return this.totalVenteAnnonce - this.totalFacturationM1
            },

            surfacturation() {
                return this.totalFacturationM1 - this.activiteM1;
            },

            surfacturationActualisee() {
                return this.surfacturation + parseFloat(this.facturationEnCours != null ? this.facturationEnCours : 0)
            },

            dateM() {
                /* let dateCreation = this.created_at == null ? moment() : moment(this.created_at); */
                /* return moment.max(dateCreation, date).startOf("day") */
                return this.date != null ? moment(this.date, "YYYY-MM-DD").startOf("day") : null;
            },

            dateM1() {
                return this.dateComptabilise ? moment(this.dateComptabilise, "YYYY-MM-DD") : null;
            },

            montantsDecs() {return this.montants("decs")},
            montantsCompta() {return this.montants("compta")},
            montantsTotal() {return this.montants("total")}
        },

        methods: {
            initM1() {
                this.dateComptabilise = moment(this.date, "YYYY-MM-DD").subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
            },

            async exportPointGestion() {
                let date = this.dateM.format("DD_MM_YYYY");
                var data = new FormData();
                data.append('dateM', date);
                data.append("decs_" + "ligneMaterielTot", this.extractFromMontants(this.montantsDecs, "ligneMateriel", "total"));
                data.append("decs_" + "ligneMaterielQte", this.extractFromMontants(this.montantsDecs, "ligneMateriel", "qty"));
                data.append("decs_" + "ligneSousTraitanceTot", this.extractFromMontants(this.montantsDecs, "ligneSousTraitance", "total"));
                data.append("decs_" + "ligneSousTraitanceQte", this.extractFromMontants(this.montantsDecs, "ligneSousTraitance", "qty"));

                data.append("decs_" + "ligneMainOeuvreLocaleTot", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreLocale", "total"));
                data.append("decs_" + "ligneMainOeuvreLocaleQte", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreLocale", "qty"));
                data.append("decs_" + "ligneMainOeuvreDeplaceeTot", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreDeplacee", "total"));
                data.append("decs_" + "ligneMainOeuvreDeplaceeQte", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreDeplacee", "qty"));
                data.append("decs_" + "ligneMainOeuvreInterimTot", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreInterim", "total"));
                data.append("decs_" + "ligneMainOeuvreInterimQte", this.extractFromMontants(this.montantsDecs, "ligneMainOeuvreInterim", "qty"));
                data.append("decs_" + "ligneEtudesTot", this.extractFromMontants(this.montantsDecs, "ligneEtudes", "total"));
                data.append("decs_" + "ligneEtudesQte", this.extractFromMontants(this.montantsDecs, "ligneEtudes", "qty"));
                data.append("decs_" + "ligneEssaisTot", this.extractFromMontants(this.montantsDecs, "ligneEssais", "total"));
                data.append("decs_" + "ligneEssaisQte", this.extractFromMontants(this.montantsDecs, "ligneEssais", "qty"));

                data.append("decs_" + "ligneTotalMaitriseTot", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitrise", "total"));
                data.append("decs_" + "ligneTotalMaitriseQte", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitrise", "qty"));
                data.append("decs_" + "ligneTotalFraisChantierTot", this.extractFromMontants(this.montantsDecs, "ligneTotalFraisChantier", "total"));
                data.append("decs_" + "ligneTotalFraisChantierQte", this.extractFromMontants(this.montantsDecs, "ligneTotalFraisChantier", "qty"));
                data.append("decs_" + "ligneTotalMaitriseEtudeTot", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitriseEtude", "total"));
                data.append("decs_" + "ligneTotalMaitriseEtudeQte", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitriseEtude", "qty"));
                data.append("decs_" + "ligneTotalMaitriseChargeAffaireTot", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitriseChargeAffaire", "total"));
                data.append("decs_" + "ligneTotalMaitriseChargeAffaireQte", this.extractFromMontants(this.montantsDecs, "ligneTotalMaitriseChargeAffaire", "qty"));

                data.append("decs_" + "ligneOutillageTot", this.extractFromMontants(this.montantsDecs, "ligneOutillage", "total"));
                data.append("decs_" + "ligneOutillageQte", this.extractFromMontants(this.montantsDecs, "ligneOutillage", "qty"));
                data.append("decs_" + "ligneDiversTot", this.extractFromMontants(this.montantsDecs, "ligneDivers", "total"));
                data.append("decs_" + "ligneDiversQte", this.extractFromMontants(this.montantsDecs, "ligneDivers", "qty"));


                data.append("compta_" + "ligneMaterielTot", this.extractFromMontants(this.montantsCompta, "ligneMateriel", "total"));
                data.append("compta_" + "ligneMaterielQte", this.extractFromMontants(this.montantsCompta, "ligneMateriel", "qty"));
                data.append("compta_" + "ligneSousTraitanceTot", this.extractFromMontants(this.montantsCompta, "ligneSousTraitance", "total"));
                data.append("compta_" + "ligneSousTraitanceQte", this.extractFromMontants(this.montantsCompta, "ligneSousTraitance", "qty"));
                data.append("compta_" + "ligneEngageTot", this.extractFromMontants(this.montantsCompta, "ligneEngage", "total"));
                data.append("compta_" + "ligneEngageQte", this.extractFromMontants(this.montantsCompta, "ligneEngage", "qty"));

                data.append("compta_" + "ligneMainOeuvreLocaleTot", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreLocale", "total"));
                data.append("compta_" + "ligneMainOeuvreLocaleQte", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreLocale", "qty"));
                data.append("compta_" + "ligneMainOeuvreDeplaceeTot", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreDeplacee", "total"));
                data.append("compta_" + "ligneMainOeuvreDeplaceeQte", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreDeplacee", "qty"));
                data.append("compta_" + "ligneMainOeuvreInterimTot", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreInterim", "total"));
                data.append("compta_" + "ligneMainOeuvreInterimQte", this.extractFromMontants(this.montantsCompta, "ligneMainOeuvreInterim", "qty"));
                data.append("compta_" + "ligneEtudesTot", this.extractFromMontants(this.montantsCompta, "ligneEtudes", "total"));
                data.append("compta_" + "ligneEtudesQte", this.extractFromMontants(this.montantsCompta, "ligneEtudes", "qty"));
                data.append("compta_" + "ligneEssaisTot", this.extractFromMontants(this.montantsCompta, "ligneEssais", "total"));
                data.append("compta_" + "ligneEssaisQte", this.extractFromMontants(this.montantsCompta, "ligneEssais", "qty"));

                data.append("compta_" + "ligneTotalMaitriseTot", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitrise", "total"));
                data.append("compta_" + "ligneTotalMaitriseQte", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitrise", "qty"));
                data.append("compta_" + "ligneTotalFraisChantierTot", this.extractFromMontants(this.montantsCompta, "ligneTotalFraisChantier", "total"));
                data.append("compta_" + "ligneTotalFraisChantierQte", this.extractFromMontants(this.montantsCompta, "ligneTotalFraisChantier", "qty"));
                data.append("compta_" + "ligneTotalMaitriseEtudeTot", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitriseEtude", "total"));
                data.append("compta_" + "ligneTotalMaitriseEtudeQte", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitriseEtude", "qty"));
                data.append("compta_" + "ligneTotalMaitriseChargeAffaireTot", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitriseChargeAffaire", "total"));
                data.append("compta_" + "ligneTotalMaitriseChargeAffaireQte", this.extractFromMontants(this.montantsCompta, "ligneTotalMaitriseChargeAffaire", "qty"));

                data.append("compta_" + "ligneOutillageTot", this.extractFromMontants(this.montantsCompta, "ligneOutillage", "total"));
                data.append("compta_" + "ligneOutillageQte", this.extractFromMontants(this.montantsCompta, "ligneOutillage", "qty"));
                data.append("compta_" + "ligneDiversTot", this.extractFromMontants(this.montantsCompta, "ligneDivers", "total"));
                data.append("compta_" + "ligneDiversQte", this.extractFromMontants(this.montantsCompta, "ligneDivers", "qty"));

                let ventes = this.decs.map(dec => ({num: dec.num, prixVenteHT: dec.prixVenteHT}))
                ventes = [...ventes, ...this.pvaos.map(pvao => ({num: pvao.libelle, prixVenteHT: pvao.prixVenteHT}))]
                data.append("ventes", JSON.stringify(ventes))
                data.append("activiteM1", this.activiteM1);
                data.append("activiteN", this.activiteN);
                data.append("activiteN1", this.activiteN1)
                data.append("facturationEnCours", this.facturationEnCours)
                data.append("facturationM1", this.totalFacturationM1)
                data.append("facturationN", this.totalFacturationN)
                data.append("facturationN1", this.totalFacturationN1)

                data.append("avancementChantier", this.avancementChantier)

                data.append("retard15", this.getEncaissementRetard(0, 15))
                data.append("retard30", this.getEncaissementRetard(15, 30))
                data.append("retard60", this.getEncaissementRetard(30, 60))
                data.append("retard90", this.getEncaissementRetard(60, 90))
                data.append("retardAudela", this.getEncaissementRetard(90, null))


                /* data.append("", ); */
                let res = await downloadFile(`api/pointGestion/${this.pointGestion.id}/export`, `point_gestion_${date}.xlsx`, data)
                if(res == -1)
                    this.showToast("danger", "Une erreur s'est produite lors de la génération de l'export", "AlertCircleIcon")
            },

            reopenPointGestion() {
                this.reopen = true;
                this.statut = this.pointGestionStatuts.find(s => s.code == "BROUILLON")
            },

            extractFromMontants(montants, id, target) {
                let res = 0;
                montants.forEach(({fields}) => {
                    let index = fields.findIndex(field => field.id == id)
                    if(index != -1) {
                        if(target == "qty")
                            res = fields[index].hasOwnProperty("qty") ? fields[index][target].value : 1
                        else if(target == "cu")
                            res = fields[index][target].value
                        else // total
                            res = fields[index][target];
                    }
                })
                return parseFloat(res);
            },

            getEncaissementRetard(debut, fin) {
                // on vérifie si la valeur du retard est enregistré dans le point de gestion
                // si c'est le cas, on récupère cette valeur, sinon on la calcule
                let target = `retardEncaissement${fin == null ? 'Audela' : fin}`;
                if(this.hasOwnProperty(target) && this[target] != null && this[target] != 0)
                    return this[target];

                return this.comptas.reduce((accumulator, compta) => {
                    let comptaDate = moment(compta.echeance)
                    let dateDebut = comptaDate.add(debut, "days").startOf("day");
                    let dateFin = null;
                    if(fin != null)
                        dateFin = comptaDate.add(fin, 'days').endOf("day");

                    let matching = this.dateM.isAfter(dateDebut) && (fin == null || this.dateM.isBefore(dateFin));
                    if(compta.montantRegle > 0 || !matching || compta.type.code != "VT")
                        return accumulator;

                    return accumulator + parseFloat(compta.montantHT);
                }, 0)
            },

            addPvao() {
                this.pvaos.push({id: generateUniqid("new"), pointGestion: this.pointGestion["@id"], libelle: "", prixVenteHT: ""})
            },

            isPvaoNew(pvao) {
                return typeof pvao.id === "string" && pvao.id.substring(0, 3) == "new"
            },

            isPvaoLoading(pvao) {return this.loadingPvaos.includes(pvao.id)},

            async insertPvao(pvao) {
                let data = {libelle: pvao.libelle, prixVenteHT: parseFloat(pvao.prixVenteHT), pointGestion: pvao.pointGestion}
                let res = await pvaoApi.addPvao(data);
                let index = this.pvaos.findIndex(p => p.id == pvao.id)
                this.pvaos.splice(index, 1, res);
            },

            async savePvao(pvao) {
                this.loadingPvaos.push(pvao.id);
                if(this.isPvaoNew(pvao))
                    await this.insertPvao(pvao)
                else
                    await pvaoApi.patchPvao(pvao.id, {libelle: pvao.libelle, prixVenteHT: parseFloat(pvao.prixVenteHT)})
                let index = this.loadingPvaos.indexOf(pvao.id);
                this.loadingPvaos.splice(index, 1)
            },

            async deletePvao(pvaoId) {
                if(!this.isPvaoNew({id: pvaoId})) {
                    let {isConfirmed} = await this.$swal({
                            title: 'Supprimer PVAO',
                            text: `Etes-vous sûr de vouloir supprimer ce PVAO?`,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Supprimer',
                            cancelButtonText: "Annuler",
                            customClass: {
                                confirmButton: 'btn btn-danger',
                                cancelButton: 'btn btn-outline-danger ml-1',
                            },
                            buttonsStyling: false,
                        })

                    if(!isConfirmed)
                        return;
                    let index = this.loadingPvaos.push(pvaoId);
                    await pvaoApi.deletePvao(pvaoId);
                    this.loadingPvaos.splice(index, 1)
                    index = this.pvaos.findIndex(pvao => pvao.id == pvaoId)
                    this.pvaos.splice(index, 1);
                }
                else {
                    let index = this.pvaos.findIndex(pvao => pvao.id == pvaoId)
                    this.pvaos.splice(index, 1);  
                }
            },
            
            updateRowsHeights(values) {
                if(this.rowsHeights == null)
                    this.rowsHeights = values;
                else {
                    for (const [key, value] of Object.entries(this.rowsHeights)) {
                        this.rowsHeights[key] = Math.max(value, values[key])
                    }
                }
            },
            async refreshStatuts() {
                this.pointGestionStatuts = await miscApi.getPointGestionStatuts();
                if(this.creating) {
                    this.statut = this.pointGestionStatuts.find(s => s.code == "BROUILLON")
                }
            },

            async fetchPointGestion() {
                this.loadingPointGestion = true;
                this.pointGestion = await pointGestionApi.getPointGestion(this.$route.params.pointGestionId);
                this.loadingPointGestion = false;
                this.reloadingPvaos = true;
                this.pvaos = await pvaoApi.getPvaos({pointGestion: this.pointGestion.id})
                this.reloadingPvaos = false;
            },

            async fetchLastPointGestion() {
                this.loadingPointGestion = true;
                let lastPG = await pointGestionApi.getLastPointGestion(this.affaireId);
                if (lastPG != null) {
                    this.pointGestion = decMontantsFields.reduce((accumulator,field)=>{return {...accumulator,[field]: lastPG[field]}},this.pointGestion);
                    this.pointGestion.avancementChantier = lastPG.avancementChantier;
                    if (lastPG.pvaos !== null) {
                        lastPG.pvaos.forEach(pvao => this.pvaos.push({id: generateUniqid("new"), pointGestion: this.pointGestion["@id"], libelle: pvao.libelle, prixVenteHT: pvao.prixVenteHT}))
                    }
                }
                this.loadingPointGestion = false;
            },

            async fetchAffaire() {
                this.affaire = await affaireApi.getAffaire(this.affaireId);
                this.fetchDecs(this.affaireId);
                this.fetchComptabilite(this.affaireId)
            },

            async fetchDecs(affaireId) {
                this.loadingDecs = true;
                if(this.dateComptabilise != null) {
                    let formattedDate = this.dateM.startOf("day").format("MM/DD/YYYY");
                    this.decs = await decApi.getDecs({affaire: affaireId, ['dateValidationChargeAffaire[before]']: formattedDate, 'statut.code':'DIRECTION'})
                }
                else
                    this.decs = [];
                this.loadingDecs = false;
            },

            async fetchComptabilite(affaireId) {
                this.loadingComptas = true;
                if(this.dateComptabilise != null) {
                    let formattedDate = this.dateM1.startOf("day").format("MM/DD/YYYY");
                    this.comptas = await comptabiliteApi.getComptabilites({affaire: affaireId, ['dateEcriture[before]']: formattedDate})
                }
                else
                    this.comptas = [];
                this.loadingComptas = false;
            },

            getDecTotal(target) {
                if(target.slice(-2) == "CU") {
                    let qteTarget = target.substring(0, target.length - 2) + "Qte";
                    let qteValue = this.getDecTotal(qteTarget);
                    let values = this.decs.map(d => d[target]);
                    let qtes = this.decs.map(d => d.hasOwnProperty(qteTarget) ? d[qteTarget]: 1);
                    let value = values.reduce((accumulator, v, index) => accumulator + v * qtes[index], 0)
                    if (this.decs.length > 0 && this.decs[0].hasOwnProperty(qteTarget))
                        return qteValue > 0 ? (value / qteValue) : 0;
                    else
                        return value;
                }
                else {
                    let qtes = this.decs.map(d => d.hasOwnProperty(target) ? d[target]: 1);
                    let value = qtes.reduce((accumulator, v) => accumulator + v, 0)
                    return value;
                }
            },

            getTotalCompta(target) {
                if(target.slice(-2) == "CU") {
                    let id = target.substr(0, target.length -2);
                    let comptas = this.comptas.filter(c => c.type.mappedMontant == id)
                    let qteValue = this.getTotalCompta(`${id}Qte`);
                    let value = comptas.reduce((accumulator, compta) => accumulator + (-1 * parseFloat(compta.montantHT)), 0)
                    return qteValue == 0 ? value : value / qteValue
                }
                else {
                    let id = target.substr(0, target.length -3);
                    let comptas = this.comptas.filter(c => c.type.mappedMontant == id)
                    return comptas.reduce((accumulator, compta) => accumulator + parseFloat(compta.qty), 0)
                }
            },

            getTotal(target) {
                let value = parseFloat(isNaN(parseFloat(this[target])) ? 0 : parseFloat(this[target]))
                if(target.slice(-2) == "CU") {
                    let id = target.substring(0, target.length - 2)
                    let compta = this.extractFromMontants(this.montantsCompta, id, "total")
                    let qteTarget = id + "Qte";
                    let qteValue = !this.hasOwnProperty(qteTarget) ? 1 : parseFloat(isNaN(parseFloat(this[qteTarget])) ? 0 : parseFloat(this[qteTarget]))
                    let qteCompta = this.getTotalCompta(qteTarget);
                    let div = qteCompta + qteValue;
                    return div > 0 ? ((value * qteValue + compta) / div) : (value * qteValue + compta)
                }
                else {
                    let compta = this.getTotalCompta(target);
                    let valueTarget = target.substr(0, target.length -3) + "CU";
                    let isValue = !isNaN(parseFloat(this[valueTarget])) && parseFloat(this[valueTarget]) > 0
                    value = !this.hasOwnProperty(target) && isValue ? 1 : value;
                    return value + compta
                }
            },

            montants(type) {
                // TODO compta en fonction des types des items compta
                let callbacks = {decs: this.getDecTotal, compta: this.getTotalCompta, total: this.getTotal};
                let montants = _.cloneDeep(this.dataMontantsEdit)

                montants.forEach(value => {value.fields.forEach(field => {
                    if(field.hasOwnProperty("qty"))
                        field.qty.value = callbacks[type](field.qty.field)
                    /* else {
                        let tempField = `${field.id}Qte`;
                        field.qty = {field: tempField, value: callbacks[type](tempField)}
                    } */
                    field.cu.value = callbacks[type](field.cu.field)

                    if(type == "compta")
                        field.extras = {avancement: {value: this.avancementCallback(field.id), prefix: '%'}}

                    let countOneIfZero = false;
                    if(["compta","total"].includes(type))
                        countOneIfZero = true;
                    field.total = this.getTotalQty(field, countOneIfZero);
                    if(!value.fields.hasOwnProperty("total"))
                        value.fields.total = 0;
                    value.fields.total += field.total;
                })})

                if(type == "compta") {
                    let montantsDecs = this.montantsDecs.map(value => value.fields.total)
                    montants.forEach((value, index) => {
                        if(!value.fields.hasOwnProperty("extras"))
                            value.fields.extras = {avancement: {value: 0, prefix: '%'}}
                        value.fields.extras.avancement.value = montantsDecs[index] == 0 ? 0 : ((value.fields.total * 100) / montantsDecs[index])
                    })
                }

                return montants;
            },

            getTotalQty(field, countOneIfZero = false) {
                let currentCU = isNaN(parseFloat(field.cu.value)) ? 0 : parseFloat(field.cu.value);
                let currentQty = !field.hasOwnProperty("qty") ? 1 : (isNaN(parseFloat(field.qty.value)) ? 1 : parseFloat(field.qty.value));
                if (countOneIfZero && currentQty == 0)
                    currentQty = 1;
                return currentCU * currentQty;
            },

            getTotalBloc(fields) {
                return fields.reduce((accumulator, field) => {
                    return accumulator + this.getTotalQty(field)
                }, 0)
            },

            avancementCallback(fieldId) {
                let comptaQte = this.getTotalCompta(fieldId + "Qte");
                comptaQte = comptaQte == 0 ? 1 : comptaQte;
                let comptaValue = this.getTotalCompta(fieldId + "CU");

                let decQte = this.getDecTotal(fieldId + "Qte");
                let decValue = this.getDecTotal(fieldId + "CU");
                
                return decQte == 0 || decValue == 0 ? 0 : (comptaQte * comptaValue * 100) / (decQte * decValue);
            },

            async validationForm(validation = "") {
                let statut = null;
                if(validation != "") {
                    let {isConfirmed} = await this.$swal({
                        title: 'Valider Point de Gestion',
                        text: 'Etes-vous sûr de vouloir valider le point de gestion ? Il ne vous sera plus possible de le modifier',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Valider',
                        cancelButtonText: "Annuler",
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    })

                    if(isConfirmed) {
                        statut = this.pointGestionStatuts.find(s => s.code == validation)
                        this.validation = true;
                    }
                    else {
                        this.validation = false;
                        return;
                    }
                }
                else
                    this.validation = false;


                this.$nextTick(async () => {
                    let pvaoRes = true;
                    let res = await this.$refs.simpleRules.validate()
                    await asyncForEach(this.pvaos, async(pvao, index) => {
                        let rules = `pvao_${index}`;
                        let temp = this.$refs[rules][0].validate();
                        if(!temp)
                            pvaoRes = false;
                    })
                                    
                    if(res && pvaoRes)
                        this.save(statut);
                    else {
                        this.showToast("danger", "Enregistrement impossible, veuillez vérifier les champs du formulaire", "AlertCircleIcon")
                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".is-invalid");
                            if(el.id == "avancementChantier")
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            else
                                el.scrollIntoView(true);
                            return;
                        });
                    }
                })
            },
            
            async save(statut = null) {
                try {
                    this.saving = true;
                    let data = this.expectedFields.filter(field => (this.creating && this[field] != null) || this[field] != this.pointGestion[field]).reduce((accumulator, currentValue) => ({...accumulator, [currentValue]: this[currentValue]}), {});

                    if(statut != null)
                        data.statut = statut;

                    // lors de la validation par la direction, on enregistre dans le point de gestion les retards d'encaissement
                    if(statut != null && statut.code == "DIRECTION") {
                        data.retardEncaissement15 = this.getEncaissementRetard(0, 15);
                        data.retardEncaissement30 = this.getEncaissementRetard(15, 30);
                        data.retardEncaissement60 = this.getEncaissementRetard(30, 60);
                        data.retardEncaissement90 = this.getEncaissementRetard(60, 90);
                        data.retardEncaissementAudela = this.getEncaissementRetard(90, null);
                    }
                    data.totalVentes = this.totalVenteAnnonce;
                    data.totalAchats = this.totalTotal;
                    data.avancementTheorique = this.avancementTheorique;


                    Object.entries(data).forEach(([key, value]) => {
                        if(value.hasOwnProperty("@id"))
                            data[key] = value['@id']
                        if(["avancementChantier", "facturationEnCours", "ligneEngageCU", "ligneEngageQte", ...decMontantsFields, ...retardEncaissementFields].includes(key))
                            data[key] = parseFloat(value);
                    })
                    
                    if(this.reopen) {
                        this.reopen = false;
                        delete data.statut;
                    }
                    let callApi = this.creating ? () => pointGestionApi.addPointGestion(data) : () => pointGestionApi.patchPointGestion(this.pointGestion.id, data)
                    if(Object.entries(data).length != 0)
                    {
                        this.pointGestion = await callApi();
                        if (this.creating) {
                            await asyncForEach(this.pvaos, async(pvao) => {
                                pvao.pointGestion = this.pointGestion["@id"];
                                await this.savePvao(pvao)
                            });
                            this.$router.replace(`/pointGestions/${this.pointGestion.id}/edit`)
                            this.creating = false;
                        }
                    }
                    await asyncForEach(this.pvaos, async(pvao) => {
                        await this.savePvao(pvao)
                    });
                    if(statut != null)
                        this.statut = statut;
                    this.showToast("success", this.creating ? "Point Gestion créé" : "Point Gestion mis à jour", "CheckIcon")
                    //this.$router.go(-1);
                } catch(e) {
                    console.log("e", e)
                    this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
                }
                finally {
                    this.saving = false;
                }
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            goBack() {
                // ! ATTENTION => fonctionne dans le cas retour à la liste. si on utilise le composant Dec à un autre endroit
                // ! il faudra surement revoir la logique. a garder en tête.
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped>
    .decmontant {
        margin-right: 0.3rem !important;
        margin-left: 0.3rem !important;
    }
</style>