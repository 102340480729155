import {callUrl} from "./api";

export default {
    async getPvaos(data = {}) {
        try {
            let res = await callUrl("GET", 'api/pvaos', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getPvao(pvaoId) {
        try {
            let res = await callUrl("GET", `api/pvaos/${pvaoId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async addPvao(data) {
        return await callUrl("POST", `api/pvaos`, data);
    },

    async patchPvao(pvaoId, data) {
        return await callUrl("PATCH", `api/pvaos/${pvaoId}`, data);
    },

    async deletePvao(pvaoId) {
        return await callUrl("DELETE", `api/pvaos/${pvaoId}`);
    },
}